import React, { useCallback } from "react";
import { Form as StyledForm } from "./SelectTimegridForm.styles";
import { Calendar, Button, Loading, FlatList, AdvancedSelect } from '../../components';
import { PlaceItem } from '../../views';
import ShiftSlotItem from "../_shared/ShiftSlotItem";
import SlotItem from '../_shared/SlotItem';

function SelectTimegridForm({
	loading,
	loadingTimegrid,
	selectedSlot,
	listMaxHeight,
	selectedDate,
	enabledDates,
	variant,
	onSelectSlot,
	currentSlotsByUser,
	onSelectDate,
	onSubmitPress,
	handleNavigateBackPress,
	withoutSteps,
	submitText,
	patient,
	selectedEventId,
	onSelectEvent,
	events,
	selectedPlaceId,
	onSelectPlace,
	places,
	selectedPlace
}) {
	const renderSlotItem = useCallback(data => (
		<SlotItem data={data} selectedSlot={selectedSlot} onSelectSlot={onSelectSlot} />
	), [selectedSlot, onSelectSlot]);

	const renderShiftSlotItem = useCallback(data => (
		<ShiftSlotItem data={data} selectedSlot={selectedSlot} onSelectSlot={onSelectSlot} />
	), [selectedSlot, onSelectSlot]);

	const renderSlotKeyExtractor = useCallback((data, index) => `${index}-${data.user.id}-${selectedDate}`, [selectedDate]);

	const renderTimegridItem = useCallback((item, index) => {
		const isSlotByShift = item.slots?.[0]?.shift;
		return (
			<div key={renderSlotKeyExtractor(item, index)}>
				{isSlotByShift ? renderShiftSlotItem(item) : renderSlotItem(item)}
			</div>
		);
	}, [renderShiftSlotItem, renderSlotItem, renderSlotKeyExtractor]);

	if (loading || !events || !places || !variant) {
		return <Loading />;
	}

	return (
		<StyledForm>
			<StyledForm.Body>
				{!withoutSteps && (
					<div className="doca-mb-4 doca-mt-2">
						<label className="doca-steps__label">
							{patient?.id ? 'Passo 2 de 2' : 'Passo 2 de 3'}
						</label>
						<div className="doca-steps__container">
							<div className={`doca-steps doca-mr-3 doca-w-full doca-steps--done doca-steps--${variant}`} />
							<div className={`doca-steps doca-w-full doca-steps--current doca-steps--${variant}`} />
							{!patient?.id && <div className={`doca-steps doca-ml-3 doca-w-full doca-steps--${variant}`} />}
						</div>
					</div>
				)}

				<StyledForm.Header.Title size="large" weight="medium">
					Agendar atendimento
				</StyledForm.Header.Title>
				<StyledForm.Header.Subtitle size="small" weight="normal" variant="gray" marginTop={8}>
					Escolha o tipo de atendimento, a data e o horário que melhor combinam com a sua agenda
				</StyledForm.Header.Subtitle>

				<div className="doca-mb-4 doca-mt-2">
					<AdvancedSelect
						value={selectedEventId}
						label="Escolha o tipo de atendimento"
						placeholder="Selecione"
						size="large"
						required
						disabled={loadingTimegrid}
						onChange={onSelectEvent}
						options={[
							{ label: 'Selecione', value: '' },
							...events.map(event => ({ label: event.preview_name || event.name, value: event.id, key: event.id }))
						]}
					/>
				</div>

				<div className="doca-mb-4">
					<AdvancedSelect
						value={selectedPlaceId}
						label="Escolha a unidade"
						placeholder="Selecione"
						size="large"
						required
						disabled={loadingTimegrid}
						onChange={onSelectPlace}
						options={[
							{ label: 'Selecione', value: '' },
							...places.map(place => ({ label: place.name, value: place.id, key: place.id }))
						]}
					/>
				</div>

				{selectedPlace && <PlaceItem place={selectedPlace} titleVariation="gray" />}

				{selectedEventId && selectedPlaceId && (
					<div className="doca-mt-4">
						{loadingTimegrid ? (
							<Loading />
						) : (
							<>
								{enabledDates.length > 0 ? (
									<>
										<Calendar
											selectedDate={selectedDate}
											enabledDates={enabledDates}
											variant={variant}
											onChangeDate={onSelectDate}
										/>

										<div className="doca-mt-4">
											{currentSlotsByUser.length > 0 ? (
												<FlatList
													data={currentSlotsByUser}
													renderItem={renderTimegridItem}
													renderKeyExtractor={renderSlotKeyExtractor}
													maxHeight={listMaxHeight}
												/>
											) : (
												<div className="doca-alert doca-alert--warning">
													Nenhum horário disponível.
												</div>
											)}
										</div>
									</>
								) : (
									<div className="doca-alert doca-alert--warning doca-mt-2">
										Não há datas para essa unidade.
									</div>
								)}
							</>
						)}
					</div>
				)}
			</StyledForm.Body>

			<Button
				variant={variant}
				block
				size="large"
				disabled={!selectedSlot}
				onClick={onSubmitPress}
				customClass={'doca-mt-4'}
			>
				{submitText}
			</Button>
			<Button
				variant="secondary"
				block
				size="large"
				onClick={handleNavigateBackPress}
				customClass={'doca-mt-4'}
			>
				<i className="doca-icon doca-icon__arrow-left" />
				Voltar
			</Button>
		</StyledForm>
	);
}

export default SelectTimegridForm;
