import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from "formik";

import PatientForm from './PatientForm';
import { create } from '../../processes/patient';
import { createAttendance } from '../../processes/attendance';
import { useCompanyStore, usePatientStore, useAnswersStore } from '../../stores';
import { DateUtils, NavigationUtils, FormatterUtils } from '../../utils';
import { showErrorToast } from '@amigoapp/doca-react';
import { omit } from 'lodash';

function PatientFormContainer() {
	const { companySettings: { settings } } = useCompanyStore();
	const { patient } = usePatientStore();
	const { answers } = useAnswersStore();
	const [loading, setLoading] = useState(false);
	const history = useHistory();

	const documentOptions = [
		{ value: 'cpf', label: 'CPF' },
		{ value: 'foreign_passport', label: 'Passaporte' },
		{ value: 'national_immigration_registry', label: 'CRNM (Carteira Nacional de Registro Migratório)' }
	]

	const hasDocumentNumber = values => {
		return values.cpf || values.foreign_passport || values.national_immigration_registry;
	}

	useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

	const validate = values => {
		const errors = {};

		if (!values.name) {
			errors.name = 'O nome é um campo obrigatório.';
		}

		if (!hasDocumentNumber(values) && !values.add_dependent) {
			errors.document = `O documento é um campo obrigatório.`;
		}

		if (!values.gender) {
			errors.gender = 'O gênero é um campo obrigatório.'
		}

		if (!values.born) {
			errors.born = 'A data de nascimento é um campo obrigatório.'
		}

		if (!values.contact_cellphone) {
			errors.contact_cellphone = 'O telefone é um campo obrigatório.'
		}

		if (!values.email) {
			errors.email = 'O email é um campo obrigatório.'
		}

		if (!values.terms_of_use?.includes('on')) {
			errors.terms_of_use = 'É necessário aceitar os termos de uso.'
		}

		return errors;
	};

	const scheduleAttendance = useCallback(async (attendanceData) => {
		const { data, error } = await createAttendance(attendanceData);

		if (error) {
			showErrorToast('Algo de errado aconteceu ao criar o agendamento.');
		} else {
			NavigationUtils.navigate(history, `/scheduled-succesfully?token=${data.external_pages_token}`);
		}
	}, [history]);

	const mountPatientData = useCallback((values) => {
		const patientData = {
			email: values.email,
			name: values.name,
			gender: values.gender,
			[values.type]:
			values.type === 'cpf'
				? FormatterUtils.clearMask(values.cpf)
				: values[values.type],
			born: DateUtils.formatDate(values.born, 'YYYY-MM-DD', 'DD/MM/YYYY'),
			contact_cellphone: FormatterUtils.clearMask(values.contact_cellphone),
			cpf_responsible: values.add_dependent ? patient?.cpf : null,
		};

		return !values[values.type] ? omit(patientData, [values.type]) : patientData;
	  }, [patient]);

	const handleSubmitPress = useCallback(async values => {
		setLoading(true);

		const { data, error } = await create({
			...mountPatientData(values),
		});

		if (error) {
			showErrorToast('Não foi possível criar o paciente.');
			setLoading(false);
		} else {

			await scheduleAttendance({
				event_id: answers.event?.id,
				place_id: answers.place?.id,
				patient_id: data.id,
				insurance_id: answers.insurance,
				start_date: answers.start_date,
				end_date: answers.end_date,
				user_id: answers.slot.user_id,
			});
			setLoading(false);
		}

	}, [setLoading, scheduleAttendance, mountPatientData, answers]);

	const {
		handleSubmit,
		handleChange,
		isValid,
		setFieldValue,
		setValues,
		values
	} = useFormik({
		initialValues: {
			born: answers?.patient?.born || '',
			name: answers?.patient?.name || '',
			type: 'cpf',
			gender: answers?.patient?.gender,
			contact_cellphone: answers?.patient?.contact_cellphone ? FormatterUtils.formatPhone(answers?.patient.contact_cellphone) : '',
			email: answers?.patient?.email || '',
			terms_of_use: answers?.patient?.terms_of_use,
			add_dependent: false
		},
		validate,
		validateOnMount: true,
		onSubmit: handleSubmitPress
	});

	const handleCheckboxAddDependent = useCallback((event) => {
		const { name, checked } = event.target;

		setValues((prevValues) => ({
			...prevValues,
			[name]: checked
		}));
	}, [setValues]);

	const onChangeWithMask = useCallback((event, mask) => {
		if (event.target.value && mask) {
			event.target.value = mask(event.target.value);
		}

		return handleChange(event);
	}, [handleChange]);

	const handleSelectChange = useCallback((event) => {
		setFieldValue('type', event.target.value);
	}, [setFieldValue]);

	const handleNavigateBack = useCallback(() => {
		NavigationUtils.navigate(history, '/select-timegrid');
	}, [history]);

	return (
		<PatientForm
			values={values}
			loading={loading}
			onChange={handleChange}
			patient={patient}
			isValid={isValid}
			variant={settings?.theme?.name}
			onSubmitClick={handleSubmit}
			setFieldValue={setFieldValue}
			onChangeWithMask={onChangeWithMask}
			handleNavigateBack={handleNavigateBack}
			documentOptions={documentOptions}
			handleSelectChange={handleSelectChange}
			handleCheckboxAddDependent={handleCheckboxAddDependent}
		/>
	);
}

export default PatientFormContainer;
